import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row space-x-4" }

import Vue from 'vue';
import Options from 'vue-class-component';

export default /*@__PURE__*/_defineComponent({
  __name: 'RockerTicketsNumber',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "text-green-500/85 text-6xl font-mono" }, "5", -1),
    _createElementVNode("div", { class: "text-white/85" }, "Salons use Rocket Tickets app", -1)
  ])))
}
}

})