import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home mb-auto bg-neutral-900 text-white/85" }
const _hoisted_2 = { class: "container mx-auto mt-8 mb-16" }
const _hoisted_3 = { class: "mx-4 md:mx-16 flex flex-col justify-center items-center" }
const _hoisted_4 = { class: "flex flex-col md:flex-row items-start justify-center md:space-x-8" }
const _hoisted_5 = { class: "flex flex-col md:flex-row items-start justify-center md:space-x-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_AboutGroup = _resolveComponent("AboutGroup")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createStaticVNode("<h1 class=\"mt-8 text-4xl font-mono text-green-500/85\">Trinh (Isaac) Vu</h1><h2 class=\"font-mono text-lg text-white/50\">Software Engineer</h2><div class=\"my-4 flex flex-col md:flex-row md:space-x-8 md:justify-center\"><a class=\"flex flex-row items-center space-x-2\" href=\"tel:+14055105975\"><i class=\"fa-solid fa-phone fa-sm w-4\"></i><p>(405) 510-5975</p></a><a class=\"flex flex-row items-center space-x-2\" href=\"mailto:txvu100@gmail.com\"><i class=\"fa-solid fa-envelope fa-xs w-4\"></i><p>txvu100@gmail.com</p></a><div class=\"flex flex-row items-center space-x-2\"><i class=\"fa-solid fa-map-pin fa-xs w-4\"></i><p>Oklahoma, U.S.</p></div></div>", 3)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AboutGroup, {
            groupName: "EXPERIENCE",
            items: $data.experienceItems
          }, null, 8, ["items"]),
          _createVNode(_component_AboutGroup, {
            groupName: "EDUCATION",
            items: $data.educationItems
          }, null, 8, ["items"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AboutGroup, {
            groupName: "PROJECTS",
            items: $data.projectItems
          }, null, 8, ["items"]),
          _createVNode(_component_AboutGroup, {
            groupName: "SKILLS",
            items: $data.skillItems
          }, null, 8, ["items"])
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}