<!-- eslint-disable max-len -->
<template>
  <div class="home bg-neutral-900">
    <NavBar />

<!--     Hero Section-->
    <section id="hero" class="bg-neutral-900">
      <div class="container flex flex-col mx-auto space-y-0 md:space-y-0 justify-evenly ">
        <img src="../assets/banner.png" alt="" class="md:hidden">
        <img src="../assets/banner2.jpg" alt="" class="hidden md:block md:mx-16">

      </div>
    </section>

    <div class=" text-white/85">
      <div class="container mx-auto">
        <div class="bg-neutral-800 flex flex-col md:flex-row justify-center items-center md:mx-16 py-16 md:py-20 md:rounded-b-xl md:px-8">
          <div class="text-4xl md:w-1/2 font-bold font-jetbrains font-mono md:text-6xl text-center md:text-left">
            Software Lab
            <div class="text-lg text-green-500/85 ml-2 mt-2 font-mono">Fueled by iced coffee</div>
          </div>
          <div class="flex flex-col md:w-1/2 mt-16 md:mt-2 justify-center">
            <div class="text-center justify-center md:text-left mx-4 md:mx-0">
              Howdy! I'm Isaac, and welcome to my world of side projects – <span class="text-green-500/85">3AM Lab</span>.
              Born during my college "vampire mode" nights, these creations are fueled by late-night inspiration.
              Although my day job is now a thing, the 3AM name lives on because that's when software gets made.
              <br/>Happy coding and stay caffeinated 🔥
            </div>
            <div class="flex justify-center md:justify-start mt-4 md:mt-4">
              <router-link class="px-4 py-2 h-10 text-black bg-green-500/75 rounded-full hover:bg-green-500 baseline" to="/about">Contact me</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" container mx-auto md:my-8">
        <div class="bg-neutral-900 md:mx-16 md:rounded-xl flex flex-col md:flex-row py-16 md:space-x-8 md:items-start md:justify-items-start items-center justify-center text-sm md:text-md font-jetbrains font-mono">
          <div class="text-gray-400"># What I'm working on</div>
          <div class="text-white/85"><span class="text-green-500/85">trinhvu@3amlab</span>:<span class="text-blue-500/85">~/projects</span>$ ls -l</div>
        </div>
    </div>

    <div class="container mx-auto">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mx-4 md:mx-16 mb-8">
        <RocketTicketsWidget />
        <OnlineAppointmentWidget />
        <LilLinkWidget />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-1 gap-8 mx-4 md:mx-16">
        <WebsiteDesignWidget />
      </div>
    </div>

<!--    <div class=" container mx-auto md:my-8">-->
<!--      <div class="bg-neutral-900 md:mx-16 md:rounded-xl flex flex-col md:flex-row py-16 md:space-x-8 md:items-start md:justify-items-start items-center justify-center text-sm md:text-md font-jetbrains font-mono">-->
<!--        <div class="text-gray-400"># Numbers speak volumes</div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="container mx-auto">-->
<!--      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 mx-4 md:mx-16 mb-4 md:mb-8">-->
<!--        <RockerTicketsNumber />-->
<!--        <RockerTicketsNumber />-->
<!--        <RockerTicketsNumber />-->
<!--        <RockerTicketsNumber />-->
<!--      </div>-->
<!--    </div>-->

    <div class="my-8">.</div>
    <Footer />

  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HomeBanner from '@/components/home-component/HomeBanner.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import OnlineAppointmentWidget from '@/components/home-component/OnlineAppointmentWidget.vue';
import LilLinkWidget from '@/components/home-component/LilLinkWidget.vue';
import WebsiteDesignWidget from '@/components/home-component/WebsiteDesignWidget.vue';
import RockerTicketsNumber from '@/components/home-component/RockerTicketsNumber.vue';

@Options({
  components: {
    RockerTicketsNumber,
    WebsiteDesignWidget,
    LilLinkWidget,
    OnlineAppointmentWidget,
    NavBar,
    Footer,
    HomeBanner,
    RocketTicketsWidget,
  },
})
export default class HomeView extends Vue { }
</script>
