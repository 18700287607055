import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Vue from 'vue';
import Options from 'vue-class-component';

export default /*@__PURE__*/_defineComponent({
  __name: 'WebsiteDesignWidget',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/rocket-tickets",
    class: "bg-pink-500/50 hover:bg-pink-500/75 pt-4 px-4 rounded-xl"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "flex flex-col md:items-start" }, [
        _createElementVNode("div", { class: "flex flex-col items-start" }, [
          _createElementVNode("div", { class: "text-xl font-bold text-white/85" }, "Website Design"),
          _createElementVNode("div", { class: "text-white/85" }, "#website")
        ]),
        _createElementVNode("div", { class: "mt-4 mb-2 text-l font-medium text-white/85" }, " Custom, user-friendly websites designed to make your business shine online. "),
        _createElementVNode("div", { class: "mb-4 text-l font-medium text-neutral-400" }, " Barbershops • Salons • Restaurants • Small businesses ")
      ], -1)
    ])),
    _: 1
  }))
}
}

})