<template>
  <div class="home mb-auto bg-neutral-900 text-white/85">
    <!--      <div class="home mb-auto">-->
    <NavBar/>

    <div class="container mx-auto mt-8 mb-16">
      <div class="mx-4 md:mx-16 flex flex-col justify-center items-center">

        <h1 class="mt-8 text-4xl font-mono text-green-500/85">Trinh (Isaac) Vu</h1>
        <h2 class="font-mono text-lg text-white/50">Software Engineer</h2>

        <div class="my-4 flex flex-col md:flex-row md:space-x-8 md:justify-center">
          <a class="flex flex-row items-center space-x-2" href="tel:+14055105975">
            <i class="fa-solid fa-phone fa-sm w-4"></i>
            <p>(405) 510-5975</p>
          </a>

          <a class="flex flex-row items-center space-x-2" href="mailto:txvu100@gmail.com">
            <i class="fa-solid fa-envelope fa-xs w-4"></i>
            <p>txvu100@gmail.com</p>
          </a>

          <div class="flex flex-row items-center space-x-2">
            <i class="fa-solid fa-map-pin fa-xs w-4"></i>
            <p>Oklahoma, U.S.</p>
          </div>

        </div>

        <div class="flex flex-col md:flex-row items-start justify-center md:space-x-8">
          <AboutGroup groupName="EXPERIENCE" :items="experienceItems"/>
          <AboutGroup groupName="EDUCATION" :items="educationItems"/>
        </div>

        <div class="flex flex-col md:flex-row items-start justify-center md:space-x-8">
          <AboutGroup groupName="PROJECTS" :items="projectItems"/>
          <AboutGroup groupName="SKILLS" :items="skillItems"/>
        </div>
      </div>
    </div>


    <Footer/>
  </div>
</template>

<script lang="ts">
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import AboutGroup from '@/views/about/components/AboutGroup.vue';

export default {
  name: 'AboutView',
  components: {
    Footer,
    NavBar,
    AboutGroup,
    // RocketTicketsWidget,
  },
  data() {
    return {
      experienceItems: [
        {
          'institution': 'MSCI Inc.',
          'url': 'https://www.msci.com',
          'icon': 'https://s3-symbol-logo.tradingview.com/msci--big.svg',
          'title': 'Software Engineer',
          'start': '2022',
          'end': 'Present',
          'descriptions': [
            'Release software applications',
            'Worked with a team to develop and maintain software applications',
          ],
        },
        {
          'institution': 'Power Costs Inc.',
          'url': 'https://www.powercosts.com',
          'icon': 'https://avatars.githubusercontent.com/u/44608073?s=200&v=4',
          'title': 'Intern',
          'start': '',
          'end': '2021',
          'descriptions': [
            'Wrote unit tests for the Risk Management project.',
            'Supported developers in developing and enhancing functionalities and features of Power Costs Inc. software products.',
          ],
        },
        {
          'institution': 'University of Central Oklahoma',
          'url': 'https://www.uco.edu',
          'icon': 'https://styles.redditmedia.com/t5_2s1fw/styles/communityIcon_tw9s3lcju3831.png',
          'title': 'Student Tutor & Research Assistant',
          'start': '',
          'end': '2021',
          'descriptions': [
            'Computer Science lab tutor.',
            'Created a mobile app to collect data for the Suicide Prevention project using Ecological Momentary Assessment (EMA) data.',
          ],
        },
      ],
      educationItems: [
        {
          'institution': 'University of Central Oklahoma',
          'url': 'http://www.uco.edu',
          'icon': 'https://styles.redditmedia.com/t5_2s1fw/styles/communityIcon_tw9s3lcju3831.png',
          'title': 'Bachelor of Computer Science',
          'start': '2020',
          'end': '2022',
          'descriptions': [
            '3.74 GPA',
            'President\'s Honor Roll.',
            'Distinguished Global Scholar',
            'John Taylor Beresford Endowed Scholar',
            'Oklahoma Research Day 2021 presenter'
          ],
        },
        {
          'institution': 'Oklahoma City Community College',
          'url': 'http://www.uco.edu',
          'icon': 'https://www.occc.edu/wp-content/uploads/elementor/thumbs/cropped-occc-site-icon-e1662492452703-qk413bazkzy76k4r33a940dukt7gnipx8jbsid6czo.png',
          'title': 'Associate of Science',
          'start': '2017',
          'end': '2019',
          'descriptions': [
            '3.77 GPA',
            'President\'s Honor Roll.',
          ],
        },
        {
          'institution': 'University of Technical Education HCM City',
          'url': 'http://www.uco.edu',
          'icon': 'https://static.wixstatic.com/media/c0d3eb_59a07945c987432b9f7f7bbb1978280f~mv2.png/v1/fill/w_706,h_706,al_c/Logo%20tr%C6%B0%E1%BB%9Dng%20%C4%91%E1%BA%A1i%20h%E1%BB%8Dc%20(layout%20tr%C3%B2n)-04.png',
          'title': 'Bachelor of Mechatronics Engineering',
          'start': '2011',
          'end': '2015',
          'descriptions': [
            'Conducted several hands-on school projects for pneumatic system, robotic, printed circuit board (PCB) ...',
          ],
        },

      ],
      projectItems: [
        {
          'institution': 'Rocket Tickets',
          'url': 'http://www.uco.edu',
          'icon': 'https://play-lh.googleusercontent.com/quSUNGQVQy0zyqS-0K0AG_kjQ3Wm4rIcZdezP79GpEj2iZQplko0sBrZ7SPOH--XsnQZ',
          'title': 'An app to manage your salon tickets, appointments, and payrolls.',
          'descriptions': [
            'Runs on Android, iOS, Kindles, and Windows.',
            'Helps salon owners cut down on time spent on managing appointments and payrolls.',
            'Update data in real-time on all devices.',
            'Trusted by 5 salons in the U.S.',
          ],
          'technologies': ['Flutter', 'Dart', 'Firestore', 'Flask', 'Python'],
        },
        {
          'institution': 'Online Booking',
          'url': 'http://www.uco.edu',
          'icon': '',
          'title': 'An online booking system for a small business',
          'descriptions': [
            'A web app that allows customers to book appointments online according to the salon\'s schedule.',
            'Remind customers of their appointments via text messages.',
            'Easy to use for all demographic.',
          ],
          'technologies': ['Vue.js', 'JavaScript', 'Flask', 'Python', 'Firestore'],
        },
        {
          'institution': 'Lil\' Link',
          'url': 'http://www.uco.edu',
          // 'icon': 'https://img.icons8.com/?size=100&id=z0B0byJc7s2O&format=png&color=000000',
          'icon': 'https://img.icons8.com/?size=100&id=nIpO2VOTRgE8&format=png&color=000000',
          'title': 'A URL shortening service',
          'descriptions': [
            'Helps users shorten long URLs to share on social media.',
            'Generate QR codes for the shortened URLs and easily to download.',
          ],
          'technologies': ['Vue.js', 'JavaScript', 'Flask', 'Python', 'Firestore'],
        },
        {
          'institution': 'Websites',
          'url': 'http://www.uco.edu',
          'icon': 'https://img.icons8.com/?size=100&id=XgVsZZvTh0tg&format=png&color=000000',
          'title': 'Design and develop websites for small businesses',
          'descriptions': [
            'https://3amlab.dev',
            'https://morganoasisnails.com',
          ],
          'technologies': ['Vue.js', 'JavaScript', 'Figma'],
        },
      ],
      skillItems: [
        {
          'skills':
              ['Java',
                'Gradle',
                'Python',
                'Flask',
                'JavaScript',
                'Vue.js',
                'Tailwind CSS',
                // 'Go',
                'Azure DevOps',
                'Ansible',
                'Docker',
                'Kubernetes',
                'Prometheus',
                // 'Grafana',
                // 'Terraform',
                'Flutter',
                'Dart',
                'Firebase',
                'Selenium',
                'Git',
                'Figma']
        },
      ],
    };
  },
};

</script>
