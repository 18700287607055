<script setup lang="ts">

import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';
import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import { useCurrentUser, useDocument } from 'vuefire';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';
import { doc, getFirestore} from 'firebase/firestore';

const user = useCurrentUser();
const auth = getAuth();

function subscribe() {
  console.log('subscribe');
}
</script>

<template>
  <div class="home mb-auto">
    <NavBar/>
    <RocketTicketsNavBar/>
    <div class="container mx-auto">
      <div v-if="user" class="flex flex-col items-center mx-4 my-16">
<!--        <stripe-buy-button-->
<!--            buy-button-id="buy_btn_1QNosGKBzyZUvQD26LP9cNnn"-->
<!--            publishable-key="pk_test_51QFizOKBzyZUvQD2WPqeeEpmrgvykH88t8BkMeVx0YSyf9jCzOZgACehf2Esy61MUGNT6twNTX98lnTZOEs9hd9r00muNsj9Ao"-->
<!--        >-->
<!--        </stripe-buy-button>-->

<!-- Subscript button-->
        <a
            target="_blank"
            :href="`https://buy.stripe.com/test_aEU7ty0HIdA9dMIaEE?prefilled_email=${user.email}`"
            class="bg-amber-600 text-white font-bold py-2 px-4 rounded-full"
        >
          Subscribe
        </a>
      </div>
    </div>
    <Footer/>
  </div>

</template>

<style scoped>

</style>
