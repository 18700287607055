<script setup lang="ts">

import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';
import { onMounted, ref } from 'vue';

import {
  getAuth,
  getRedirectResult,
  signInWithRedirect,
    GoogleAuthProvider,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'

import { useCurrentUser } from 'vuefire'
import router from '@/router';

const auth = getAuth();
const authError = ref('');

const email = ref('');
const emailError = ref('');
const password = ref('');
const passwordError = ref('');

const user = useCurrentUser()

function validateEmail(email: string) {
  if (email === '') {
    emailError.value = 'Email is required';
    return false;
  } else {
    emailError.value = '';
    return true;
  }
}

function validatePassword(password: string) {
  if (password === '') {
    passwordError.value = 'Password is required';
    return false;
  } else {
    passwordError.value = '';
    return true;
  }
}

function submitForm() {
  if (!validateEmail(email.value) || !validatePassword(password.value)) {
    return;
  }

  signInWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
        authError.value = error.message.split(':')[1];
      }).then(
      () => {
        // go to another page
        router.push('/rocket-tickets/store');
      }
  );
}

function handleInputChange() {
}

onMounted(() => {
  // signInWithRedirect(auth, new GoogleAuthProvider());
})

</script>

<template>
  <div class="home mb-auto">
    <div class="bg-neutral-800">
      <NavBar/>
    </div>
    <RocketTicketsNavBar/>
    <div>
      <div class="container mx-auto">

<!--        <p v-if="user">Hello {{ user.providerData[0].email }}</p>-->

<!--        <div>{{ email }}</div>-->
<!--        <div>{{ password }}</div>-->

        <form @submit.prevent="submitForm" autocomplete="on" class="flex flex-col bg-white mx-4 md:mx-16 my-16 p-8 rounded-xl shadow-xl md:space-x-4">

          <div class="md:flex-1 mb-4">
            <label class="block text-gray-500 text-sm mb-2 text-justify" for="email">
              Email
            </label>
            <input
                v-model="email"
                @input="handleInputChange"
                type="email"
                id="email"
                name="email"
                class="w-full p-2 border border-gray-400 rounded-xl"
                required
            />
            <div class="text-red-500 text-sm">{{ emailError }}</div>
          </div>

          <div class="md:flex-1 mb-4">
            <label class="block text-gray-500 text-sm mb-2 text-justify" for="password">
              Password
            </label>
            <input
                v-model="password"
                @input="handleInputChange"
                type="password"
                id="password"
                name="password"
                class="w-full p-2 border border-gray-400 rounded-xl"
                required
            />
            <div class="text-red-500 text-sm">{{ passwordError }}</div>
          </div>

          <button type="submit" class="bg-blue-500 text-white p-2 rounded-md">Sign in</button>

          <div class="text-red-500 text-sm">{{ authError }}</div>
        </form>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<style scoped>

</style>
