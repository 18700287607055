import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home mb-auto" }
const _hoisted_2 = { class: "container mx-auto" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col items-center mx-4 my-16"
}
const _hoisted_4 = ["href"]

import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';
import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import { useCurrentUser, useDocument } from 'vuefire';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';
import { doc, getFirestore} from 'firebase/firestore';


export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsBilling',
  setup(__props) {


const user = useCurrentUser();
const auth = getAuth();

function subscribe() {
  console.log('subscribe');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NavBar),
    _createVNode(RocketTicketsNavBar),
    _createElementVNode("div", _hoisted_2, [
      (_unref(user))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("a", {
              target: "_blank",
              href: `https://buy.stripe.com/test_aEU7ty0HIdA9dMIaEE?prefilled_email=${_unref(user).email}`,
              class: "bg-amber-600 text-white font-bold py-2 px-4 rounded-full"
            }, " Subscribe ", 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(Footer)
  ]))
}
}

})