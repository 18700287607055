<template>
  <div class="mb-4 p-4 rounded-xl items-start border border-gray-300 md:w-[390px] md:mr-4 text-gray-600 hover:text-indigo-500">
    <div class="font-bold text-md text-justify ">&nbsp;{{group.name}}</div>

    <div class="flex flex-col">
      <ServiceButton
        class=""
        v-for="service in group.services"
        :key="service.id"
        :service="service"
        :selectingServices="selectingServices"
        @addService="addService"
        @removeService="removeService"
      />
    </div>

  </div>

</template>

<script>
import ServiceButton
  from '@/views/projects/rocket-tickets/appointment/book-appointment/services-tab/ServiceButton.vue';

export default {
  name: 'GroupPanel',
  components: {
    ServiceButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    selectingServices: Array,
  },
  methods: {
    addService(service) {
      console.log(`Group: Add ${service.short_name} service`);
      this.$emit('addService', service);
    },
    removeService(service) {
      console.log(`Group: Remove ${service.short_name} service`);
      this.$emit('removeService', service);
    },
  },
};
</script>
