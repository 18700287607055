import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../../src/assets/rocket-tickets/rt-logo.png'


const _hoisted_1 = { class: "sticky bg-whites/50 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50 top-0 h-16" }
const _hoisted_2 = { class: "container mx-auto flex flex-col h-16" }
const _hoisted_3 = { class: "flex flex-row justify-between items-center mx-4 md:mx-16" }
const _hoisted_4 = { class: "flex flex-row text-sm font-medium space-x-4 items-baseline" }

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import RocketTicketsTabButton from '@/views/projects/rocket-tickets/RocketTicketsTabButton.vue';
import { useCurrentUser } from 'vuefire'
import { getAuth, signOut } from "firebase/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import router from '@/router';



export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsNavBar',
  setup(__props) {

const user = useCurrentUser()
const auth = getAuth();

function getShortName() {
  return user.value.displayName.split(' ')[0];
}

function signOutUser() {
  signOut(auth).then(() => {
    router.push('/rocket-tickets/auth');
  }).catch((error) => {
    console.log('Sign out error');
  });
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_User = _resolveComponent("User")!
  const _component_CreditCard = _resolveComponent("CreditCard")!
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "flex flex-row items-center",
          to: "/rocket-tickets"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              alt: "",
              src: _imports_0,
              class: "w-16 h-16 ml-0 md:ml-0"
            }, null, -1),
            _createElementVNode("div", { class: "hidden md:block text-xl md:text-xl font-bold text-gray-800" }, "Rocket Tickets", -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(RocketTicketsTabButton, {
            tabName: "Overview",
            pathName: "",
            class: "hidden md:block"
          }),
          _createVNode(RocketTicketsTabButton, {
            tabName: "Download",
            pathName: "download"
          }),
          _createVNode(RocketTicketsTabButton, {
            tabName: "Pricing",
            pathName: "pricing"
          }),
          _createVNode(RocketTicketsTabButton, {
            tabName: "Guide",
            pathName: "guide"
          }),
          (!_unref(user))
            ? (_openBlock(), _createBlock(RocketTicketsTabButton, {
                key: 0,
                tabName: "Sign in",
                pathName: "auth"
              }))
            : (_openBlock(), _createBlock(_unref(DropdownMenu), { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Button, { class: "bg-amber-500 text-amber-800 rounded-full px-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(getShortName()), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(DropdownMenuContent), { class: "w-56" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DropdownMenuLabel), null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("My Account")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(DropdownMenuSeparator)),
                      _createVNode(_unref(DropdownMenuGroup), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(DropdownMenuItem), null, {
                            default: _withCtx(() => [
                              _createVNode(_component_User, { class: "mr-2 h-4 w-4" }),
                              _createVNode(_component_router_link, { to: "/rocket-tickets/store" }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode("Profile")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(DropdownMenuItem), null, {
                            default: _withCtx(() => [
                              _createVNode(_component_CreditCard, { class: "mr-2 h-4 w-4" }),
                              _createVNode(_component_router_link, { to: "/rocket-tickets/store/billing" }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode("Billing")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(DropdownMenuSeparator)),
                      _createVNode(_unref(DropdownMenuItem), null, {
                        default: _withCtx(() => [
                          _createVNode(_component_LogOut, { class: "mr-2 h-4 w-4" }),
                          _createElementVNode("button", { onClick: signOutUser }, "Sign out")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ])
      ])
    ])
  ]))
}
}

})