<template>
  <div class="flex flex-col items-start">
    <!--  <hr class="border-gray-300 mt-6" />-->

<!--    <div>-->
<!--      Pick your refer reminder method-->
<!--    </div>-->

<!--    <div class="flex flex-row space-x-6">-->
<!--      <input type="checkbox" class="mr-4">SMS-->
<!--      <input type="checkbox" class="mr-4">Email-->
<!--    </div>-->
    <div class="flex flex-row items-start">
      <input type="checkbox"
             v-if="!isBooked"
             v-model="dataIsConsented"
             required
             @change="handleCheckBoxChange"
             id="dataIsConsented"
             name="dataIsConsented"
             class="mr-4 mt-1"/>
      <label v-if="!isBooked" class="block text-gray-500 text-sm justify-start text-left" for="dataIsConsented">
        I consent to receiving text message reminders for appointments, updates, and other relevant information from {{ store.name }}. Standard messaging rates may apply.
      </label>
      <label v-if="isBooked" class="block text-gray-500 text-sm text-justify" for="dataIsConsented">
        {{dataIsConsented ? 'You consent' : 'You did NOT consent'}} to receiving text message reminders for appointments, updates, and other relevant information from {{ store.name }}. {{dataIsConsented ? 'Standard messaging rates may apply.' : 'We won\'t send you any text messages.'}}
      </label>
    </div>

<!--    <div class="flex flex-row items-start mt-4" v-if="dataIsConsented">-->
<!--      <input type="checkbox"-->
<!--             v-if="!isBooked"-->
<!--             v-model="dataWantsToReceiveConfirmationRightAfter"-->
<!--             required-->
<!--             @input="handleCheckBoxChange"-->
<!--             id="dataWantsToReceiveConfirmationRightAfter"-->
<!--             name="dataWantsToReceiveConfirmationRightAfter"-->
<!--             class="mr-4 mt-1"/>-->
<!--      <label v-if="!isBooked" class="block text-gray-500 text-sm text-justify" for="dataWantsToReceiveConfirmationRightAfter">-->
<!--        Send me the confirmation via text message.-->
<!--      </label>-->
<!--      <label v-if="isBooked" class="block text-gray-500 text-sm text-justify" for="dataWantsToReceiveConfirmationRightAfter">-->
<!--        {{dataWantsToReceiveConfirmationRightAfter ? 'We sent you' : 'We won\'t send you'}} the confirmation via text message.-->
<!--      </label>-->
<!--    </div>-->

<!--    <div class="flex flex-row items-start mt-4" v-if="dataIsConsented">-->
<!--      <input type="checkbox"-->
<!--             v-if="!isBooked"-->
<!--             v-model="dataIs24HoursReminded"-->
<!--             required-->
<!--             @input="handleCheckBoxChange"-->
<!--             id="dataIs24HoursReminded"-->
<!--             name="dataIs24HoursReminded"-->
<!--             class="mr-4 mt-1"/>-->
<!--      <label v-if="!isBooked" class="block text-gray-500 text-sm text-justify" for="dataIs24HoursReminded">-->
<!--        Send me a reminder 24 hours before the appointment.-->
<!--      </label>-->
<!--      <label v-if="isBooked" class="block text-gray-500 text-sm text-justify" for="dataIs24HoursReminded">-->
<!--        {{dataIs24HoursReminded ? 'We will send you' :  'We won\'t send you'}} a reminder 24 hours before the appointment via text message.-->
<!--      </label>-->
<!--    </div>-->

<!--    <div>dataIsConsented: {{ this.dataIsConsented }} <br/> dataWantsToReceiveConfirmationRightAfter: {{ this.dataWantsToReceiveConfirmationRightAfter }} <br/> dataIs24HoursReminded: {{ this.dataIs24HoursReminded }}</div>-->


  </div>
</template>

<script>

export default {
  name: 'ReminderOptions',
  components: {
  },
  props: {
    preferredReminderMethod: String,
    isConsented: Boolean,
    wantsToReceiveConfirmationRightAfter: Boolean,
    is24HoursReminded: Boolean,
    store: {
      type: Object,
      required: true,
    },
    isBooked: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dataPreferredReminderMethod: 'SMS',
      dataIsConsented: true,
      dataWantsToReceiveConfirmationRightAfter: true,
      dataIs24HoursReminded: true,
    };
  },
  methods: {
    handleCheckBoxChange() {
        if (this.dataIsConsented) {
          this.dataWantsToReceiveConfirmationRightAfter = true;
          this.dataIs24HoursReminded = true;
        }
        if (!this.dataIsConsented) {
          this.dataWantsToReceiveConfirmationRightAfter = false;
          this.dataIs24HoursReminded = false;
        }



      // this.isConsented = this.dataIsConsented;
      // this.wantsToReceiveConfirmationRightAfter = this.dataWantsToReceiveConfirmationRightAfter;
      // this.is24HoursReminded = this.dataIs24HoursReminded;

      // console.log('ReminderOptions: handleCheckBoxChange: this.isConsented: ', this.isConsented);
      // console.log('ReminderOptions: handleCheckBoxChange: this.wantsToReceiveConfirmationRightAfter: ', this.wantsToReceiveConfirmationRightAfter);
      // console.log('ReminderOptions: handleCheckBoxChange: this.is24HoursReminded: ', this.is24HoursReminded);

      console.log(this.dataIsConsented.toString());
      console.log(this.dataWantsToReceiveConfirmationRightAfter.toString());
      console.log(this.dataIs24HoursReminded.toString());

      // this.$emit('updateReminderOptions', this.isConsented, this.wantsToReceiveConfirmationRightAfter, this.is24HoursReminded);
      this.$emit('updateReminderOptions', this.dataIsConsented, this.dataWantsToReceiveConfirmationRightAfter, this.dataIs24HoursReminded);
    },
  },
};

</script>
