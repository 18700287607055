import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/booking/booking-1.png'
import _imports_1 from '@/assets/booking/booking-2.png'
import _imports_2 from '@/assets/booking/booking-3.png'
import _imports_3 from '@/assets/booking/booking-4.png'
import _imports_4 from '@/assets/booking/booking-5.png'
import _imports_5 from '@/assets/booking/booking-6.png'


const _hoisted_1 = { class: "home mb-auto bg-neutral-900 text-white/85" }
const _hoisted_2 = { class: "container mx-auto mb-16" }
const _hoisted_3 = { class: "mx-4 md:mx-16 flex flex-col justify-center items-center" }
const _hoisted_4 = { class: "grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4" }
const _hoisted_5 = { class: "text-indigo-500/85 bg-neutral-800 hover:bg-neutral-700 rounded-xl w-64 p-4" }
const _hoisted_6 = { class: "text-xl font-bold" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }

import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OnlineBookingAdView',
  setup(__props) {


const stores = [
  {
    name: 'Test Salon',
    cute_id: 'test-salon',
    address: '246 Saratoga Ave, Santa Clara, CA 95051',
    phone: '(408) 554-7100'
  },
  {
    name: 'Morgan Oasis Nails',
    cute_id: 'morgan-oasis-nails',
    address: '1719 S Morgan Rd, Oklahoma City, OK 73128',
    phone: '(405) 265-2494'
  },
  {
    name: 'Perfect Nails Moore',
    cute_id: 'perfect-nails-moore',
    address: '1605 S I-35 Service Rd, Moore, OK 73160',
    phone: '(405) 790-1195'
  },
  {
    name: 'Dragon Nails and Spa',
    cute_id: 'dragon-nails-spa',
    address: '2420 NW 150th St #2, Oklahoma City, OK 73134',
    phone: '(405) 849-5672'
  },
  // {
  //   name: 'Nail It',
  //   cute_id: 'FORO9YnEzsgKSeeyzbY7Gzn0oMn2'
  // },

];

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NavBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"mt-16 text-4xl font-bold text-center text-indigo-500\">Online Appointment Bookings</div><div class=\"mt-4 text-xl font-bold text-center text-neutral-500\">Easy to choose available time slot · Notify to salon&#39;s devices · Remind customer when the appointment coming</div><div class=\"flex md:flex-row flex-col items-center mt-16 md:space-x-8 space-y-4\"><img alt=\"\" src=\"" + _imports_0 + "\" class=\"h-96 w-64 object-cover object-top rounded-xl\"><img alt=\"\" src=\"" + _imports_1 + "\" class=\"h-96 w-64 object-cover object-top rounded-xl\"><img alt=\"\" src=\"" + _imports_2 + "\" class=\"h-96 w-64 object-cover object-top rounded-xl\"></div><div class=\"flex md:flex-row flex-col items-center md:mt-8 mt-4 md:space-x-8 space-y-4\"><img alt=\"\" src=\"" + _imports_3 + "\" class=\"h-96 w-64 object-cover object-top rounded-xl\"><img alt=\"\" src=\"" + _imports_4 + "\" class=\"h-96 w-64 object-cover object-top rounded-xl\"><img alt=\"\" src=\"" + _imports_5 + "\" class=\"h-96 w-64 object-cover object-top rounded-xl\"></div><div class=\"my-16 text-4xl font-bold text-indigo-500 text-center\">Check out our customers</div>", 5)),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(stores, (store) => {
            return _createElementVNode("div", {
              key: store.cute_id,
              class: ""
            }, [
              _createVNode(_component_router_link, {
                class: "",
                to: {name: 'appointment-booking', params: { storeCuteID: store.cute_id }}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(store.name), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(store.address), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(store.phone), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          }), 64))
        ])
      ])
    ]),
    _createVNode(Footer)
  ]))
}
}

})