<template>
<!--  <button class="h-16 flex flex-row" v-if="selectingServices.some(x => x.id === service.id)" type="button" @click="handleRemove">-->
<!--    <div class="grow flex flex-row bg-indigo-500 text-white rounded-l-xl px-4 mt-3 text-sm justify-center items-center">-->
<!--      <div class="grow text-justify">{{ service.short_name }}</div>-->
<!--    </div>-->
<!--    <button class="bg-indigo-100 text-red-500 font-bold px-4 mt-3 ml-0.5 text-sm" type="button" @click="handleRemove">-->
<!--      <i class="fa-solid fa-minus"></i>-->
<!--    </button>-->
<!--    <div class="bg-indigo-100 text-black px-3 pt-4 mt-3 ml-0.5 text-sm justify-center items-center">-->
<!--      {{ numberOfTheSameService() }}-->
<!--    </div>-->
<!--    <button class="bg-indigo-100 text-blue-500 font-bold px-4 mt-3 ml-0.5 text-sm rounded-r-xl" type="button" @click="handleAdd">-->
<!--      <i class="fa-solid fa-plus"></i>-->
<!--    </button>-->
<!--  </button>-->

  <button class="h-14 bg-indigo-500 text-white font rounded-xl px-4 mt-3 text-sm md:hover:border md:hover:border-indigo-800" type="button" v-if="selectingServices.some(x => x.id === service.id)" @click="handleRemove">
    <div class="flex flex-row">
      <div class="grow text-justify">{{ service.verbose_name }}</div>
      <div class="text-indigo-900 mr-3" v-if="this.service.show_duration">{{ service.duration }} min </div>
      <div v-if="this.service.show_price">${{ service.price }}</div>
    </div>
  </button>

  <button class="h-14 bg-gray-100 text-black font rounded-xl px-4 mt-3 text-sm md:hover:border md:hover:border-indigo-500" type="button" v-else @click="handleAdd">
    <div class="flex flex-row">
      <div class="grow text-justify">{{ service.verbose_name }}</div>
      <div class="text-gray-500 mr-3" v-if="this.service.show_duration">{{ service.duration }} min </div>
      <div v-if="this.service.show_price">${{ service.price }}</div>
    </div>
  </button>


</template>

<script>
export default {
  name: 'ServiceButton',
  components: {
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    selectingServices: Array,
  },
  data() {
    return {
      // isSelected: true,
    };
  },
  methods: {
    handleAdd() {
      // this.isSelected = true;
      console.log(`Add ${this.service.short_name} service`);
      this.$emit('addService', this.service);
    },
    handleRemove() {
      console.log(`Remove ${this.service.short_name} service`);
      this.$emit('removeService', this.service);
    },
    numberOfTheSameService() {
      return this.selectingServices.filter((x) => x.id === this.service.id).length;
    },
  },
};
</script>
