<template>
<!--  <div class="text-md md:text-lg mb-6 text-indigo-500">Hi there, how can we help you with?<br/>You can pick one or more services</div>-->

  <div class="md:flex md:flex-row md:flex-wrap">
    <GroupPanel
      class=""
      v-for="group in services"
      :key="group.id"
      :group="group"
      :selectingServices="selectingServices"
      @addService="addService"
      @removeService="removeService"
    />
  </div>


<!--  <GroupPanel/>-->
</template>

<script>
import GroupPanel
  from '@/views/projects/rocket-tickets/appointment/book-appointment/services-tab/GroupPanel.vue';

export default {
  name: 'ServicesTab',
  components: {
    GroupPanel,
  },
  props: {
    services: {
      type: Object,
      required: true,
    },
    selectingServices: Array,
  },
  data() {
    return {
    };
  },
  methods: {
    addService(service) {
      console.log(`ServicesTab: Add ${service.short_name} service`);
      this.$emit('addService', service);
    },
    removeService(service) {
      console.log(`ServicesTab: Remove ${service.short_name} service`);
      this.$emit('removeService', service);
    },
  },
};
</script>
