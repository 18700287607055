import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "home mb-auto" }
const _hoisted_2 = { class: "container mx-auto" }
const _hoisted_3 = { class: "mx-4" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col items-center my-16"
}
const _hoisted_5 = { class: "text-2xl font-bold text-amber-600" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = {
  key: 0,
  class: "text-sm rounded-xl bg-neutral-100 p-4 mt-8 space-y-4"
}
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = {
  key: 0,
  class: ""
}
const _hoisted_11 = { key: 1 }

import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';
import { useCurrentUser, useDocument } from 'vuefire';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';
import { doc, getFirestore} from 'firebase/firestore';


export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsStoreProfile',
  setup(__props) {


const user = useCurrentUser();
const auth = getAuth();

const db = getFirestore();
const storeDoc = doc(db, 'accounts', user.value.uid);
const store = useDocument(storeDoc);

function signOutUser() {
  signOut(auth).then(() => {
    router.push('/rocket-tickets/auth');
  }).catch((error) => {
    console.log('Sign out error');
  });
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NavBar),
    _createVNode(RocketTicketsNavBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_unref(user))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(user).displayName), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(user).email), 1),
              (_unref(store))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[1] || (_cache[1] = _createTextVNode("Address:")),
                      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(_toDisplayString(_unref(store).address), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[4] || (_cache[4] = _createTextVNode("Phone:")),
                      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(_toDisplayString(_unref(store).phone), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
                    ]),
                    (_unref(store).cute_id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[7] || (_cache[7] = _createTextVNode("Booking URL:")),
                          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode("https://3amlab.dev/rocket-tickets/store/" + _toDisplayString(_unref(store).cute_id) + "/appointment/booking", 1),
                          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-sm text-gray-500 mt-8" }, "This page is mainly to manage your billing. Please use the app to edit your store information.", -1)),
              _createVNode(_component_router_link, { to: "/rocket-tickets/store/billing" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("div", { class: "border border-amber-600 text-amber-600 font-medium p-2 m-8 rounded-full" }, "Manage your billing", -1)
                ])),
                _: 1
              }),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (signOutUser())),
                class: "text-red-500 font-medium p-2 mb-8 rounded-full"
              }, "Sign out")
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, "loading..."))
      ])
    ]),
    _createVNode(Footer)
  ]))
}
}

})