<script setup lang="ts">

import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';
import { useCurrentUser, useDocument } from 'vuefire';
import { getAuth, signOut } from 'firebase/auth';
import router from '@/router';
import { doc, getFirestore} from 'firebase/firestore';

const user = useCurrentUser();
const auth = getAuth();

const db = getFirestore();
const storeDoc = doc(db, 'accounts', user.value.uid);
const store = useDocument(storeDoc);

function signOutUser() {
  signOut(auth).then(() => {
    router.push('/rocket-tickets/auth');
  }).catch((error) => {
    console.log('Sign out error');
  });
}
</script>

<template>
  <div class="home mb-auto">
    <NavBar/>
    <RocketTicketsNavBar/>
    <div class="container mx-auto">
      <div class="mx-4">
        <div v-if="user" class="flex flex-col items-center my-16">
          <div class="text-2xl font-bold text-amber-600">{{ user.displayName }}</div>
          <div class="text-lg">{{ user.email }}</div>

          <div v-if="store" class="text-sm rounded-xl bg-neutral-100 p-4 mt-8 space-y-4">
            <div class="">Address:<br/>{{ store.address }}<br/></div>
            <div class="">Phone:<br/>{{ store.phone }}<br/></div>
            <div v-if="store.cute_id" class="">Booking URL:<br/>https://3amlab.dev/rocket-tickets/store/{{ store.cute_id }}/appointment/booking<br/></div>
          </div>

          <div class="text-sm text-gray-500 mt-8">This page is mainly to manage your billing. Please use the app to edit your store information.</div>

<!--          <div class="">{{ store }}</div>-->



<!--          <button @click="signOutUser">Sign out</button>-->
          <router-link to="/rocket-tickets/store/billing">
            <div class="border border-amber-600 text-amber-600 font-medium p-2 m-8 rounded-full">Manage your billing</div>
          </router-link>

          <button @click="signOutUser()" class=" text-red-500 font-medium p-2 mb-8 rounded-full">Sign out</button>

        </div>
        <div v-else>loading...</div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<style scoped>

</style>
