<script lang="ts">
import Vue from 'vue';
import Options from 'vue-class-component';

export default {
  name: 'RocketTicketsWidget',
};
</script>

<template>
  <router-link to="/rocket-tickets" class="bg-amber-900 hover:bg-amber-800 pt-4 px-4 rounded-xl h-96">
<!--  <router-link to="/rocket-tickets" class="bg-neutral-800 hover:bg-neutral-700 pt-4 px-4 rounded-xl h-96">-->
    <div class="flex flex-col">
      <div class="flex flex-row items-center">
        <img alt="" src="../../assets/rocket-ticket-logo.png" class="w-12 h-12 rounded-lg" />
        <div class="flex flex-col items-start ml-2">
          <div class="text-xl font-bold text-white/85">Rocket Tickets</div>
          <div class="text-white/85">#mobile-app</div>
        </div>
      </div>

      <div class="my-4 text-l font-medium text-white/85 ml-0">
        An app to manage your salon tickets, appointments, and payrolls.
      </div>

      <img alt="" src="../../assets/rocket-tickets/rt-tickets.png" class="h-[236px] w-full object-cover object-top" />
    </div>
  </router-link>
  <!--  <router-link to="/rocket-tickets">-->
  <!--    <div class="mx-4 md:mx-16 flex flex-col md:flex-row pt-16 pb-8">-->
  <!--      <div class="flex flex-col md:w-1/2 md:items-start">-->
  <!--        <div class="flex flex-row items-center">-->
  <!--          <img alt="" src="../../assets/rocket-ticket-logo.png" class="w-16 h-16 ml-2 md:ml-0" />-->
  <!--          <div class="flex flex-col items-start ml-4">-->
  <!--            <div class="text-xl md:text-4xl font-bold text-white-900">Rocket Tickets</div>-->
  <!--            <div class="text-white-800">#mobile-app</div>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--        <div class="mt-6 text-lg md:text-2xl md:text-left text-center font-bold text-white-900 md:ml-20">-->
  <!--          An app to manage your salon<br/>tickets, appointments, and payrolls.-->
  <!--        </div>-->

  <!--&lt;!&ndash;        <div class="flex flex-col md:flex-row items-center md:items-baseline md:ml-14">&ndash;&gt;-->
  <!--&lt;!&ndash;          <div class="my-6 mx-4">&ndash;&gt;-->
  <!--&lt;!&ndash;            <router-link&ndash;&gt;-->
  <!--&lt;!&ndash;              class="px-6 py-3 h-12 w-40 text-white-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline"&ndash;&gt;-->
  <!--&lt;!&ndash;              to="/rocket-tickets">&ndash;&gt;-->
  <!--&lt;!&ndash;              Download now&ndash;&gt;-->
  <!--&lt;!&ndash;            </router-link>&ndash;&gt;-->
  <!--&lt;!&ndash;          </div>&ndash;&gt;-->
  <!--&lt;!&ndash;        </div>&ndash;&gt;-->

  <!--        <p class="hidden md:block text-start md:ml-20">-->
  <!--          <br />&#x2022; Sync your data across devices in real-time-->
  <!--          <br />&#x2022; Send daily report to staffs and managers via email/SMS-->
  <!--          <br />&#x2022; Calculate payroll-->
  <!--          <br />&#x2022; Easy to enter appointments/tickets-->
  <!--          <br />&#x2022; Send SMS reminder to customers-->
  <!--          <br />&#x2022; Prevent edit tickets without permission-->
  <!--          <br />&#x2022; Easy to work even with no receptionist-->
  <!--        </p>-->

  <!--&lt;!&ndash;        <div class="ml-24 mt-8 hidden md:block">&ndash;&gt;-->
  <!--&lt;!&ndash;          <router-link&ndash;&gt;-->
  <!--&lt;!&ndash;              class="text-white-900 font-bold underline"&ndash;&gt;-->
  <!--&lt;!&ndash;              to="/rocket-tickets"&ndash;&gt;-->
  <!--&lt;!&ndash;          >Learn more >>></router-link&ndash;&gt;-->
  <!--&lt;!&ndash;          >&ndash;&gt;-->
  <!--&lt;!&ndash;        </div>&ndash;&gt;-->
  <!--        <div class="ml-24 mt-8 hidden md:block">-->
  <!--          <router-link-->
  <!--              class="px-4 py-2 text-white-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline"-->
  <!--              to="/rocket-tickets"-->
  <!--          >Learn more <i class="fa-solid fa-angle-right"></i></router-link-->
  <!--          >-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <div class="my-10 md:mt-0 md:w-1/2 justify-center">-->
  <!--&lt;!&ndash;        <img alt="" src="../../assets/rt-2.png" class="md:hidden" />&ndash;&gt;-->
  <!--&lt;!&ndash;        <img alt="" src="../../assets/rt2-md.png" class="hidden md:block" />&ndash;&gt;-->
  <!--        <img alt="" src="@/assets/rocket-tickets/rt-tickets.png" class="md:hidden"/>-->
  <!--        <img alt="" src="@/assets/rt2-md.png" class="hidden md:block"/>-->
  <!--      </div>-->

  <!--      <p class="md:hidden text-white-900">-->
  <!--        <br />&#x2022; Sync your data across devices in real-time-->
  <!--        <br />&#x2022; Send daily report to staffs and managers via email/SMS-->
  <!--        <br />&#x2022; Calculate payroll-->
  <!--        <br />&#x2022; Easy to enter appointments/tickets-->
  <!--        <br />&#x2022; Send SMS reminder to customers-->
  <!--        <br />&#x2022; Prevent edit tickets without permission-->
  <!--        <br />&#x2022; Easy to work even with no receptionist-->
  <!--      </p>-->

  <!--      <div class="my-8 justify-center flex md:hidden">-->
  <!--        <router-link-->
  <!--          class="px-4 py-2 text-white-500 justify-end bg-amber-900 rounded-full hover:bg-amber-900 baseline"-->
  <!--          to="/rocket-tickets"-->
  <!--          >Learn more <i class="fa-solid fa-angle-right"></i></router-link-->
  <!--        >-->
  <!--      </div>-->

  <!--&lt;!&ndash;      <div class="mt-10 md:hidden">&ndash;&gt;-->
  <!--&lt;!&ndash;        <router-link&ndash;&gt;-->
  <!--&lt;!&ndash;            class="text-white-900 font-bold underline"&ndash;&gt;-->
  <!--&lt;!&ndash;            to="/rocket-tickets"&ndash;&gt;-->
  <!--&lt;!&ndash;        >Learn more >>></router-link&ndash;&gt;-->
  <!--&lt;!&ndash;        >&ndash;&gt;-->
  <!--&lt;!&ndash;      </div>&ndash;&gt;-->

  <!--      &lt;!&ndash;              <div class="mt-2">&ndash;&gt;-->
  <!--      &lt;!&ndash;                <strong class="text-white-500">Learn more</strong>&ndash;&gt;-->
  <!--      &lt;!&ndash;              </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;      <div>&ndash;&gt;-->
  <!--      &lt;!&ndash;        lalal&ndash;&gt;-->
  <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    </div>-->
  <!--  </router-link>-->
</template>
