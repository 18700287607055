import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home mb-auto bg-neutral-900 text-white/85" }

import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'WebsiteDesignAdView',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NavBar),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "container mx-auto mt-8 mb-16" }, [
      _createElementVNode("div", { class: "mx-4 md:mx-16 flex flex-col justify-center items-center" }, " dfdsfds ")
    ], -1)),
    _createVNode(Footer)
  ]))
}
}

})