import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"
import _imports_0 from '../assets/logo.png'
import _imports_1 from '../assets/rocket-ticket-logo.png'
import _imports_2 from '../assets/calendar.png'
import _imports_3 from '../assets/link.png'


const _hoisted_1 = { class: "sticky bg-neutral-800/50 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50 top-0 h-16" }
const _hoisted_2 = { class: "container flex flex-col mx-auto" }
const _hoisted_3 = { class: "flex items-center justify-between mx-4 md:mx-16" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "flex space-x-4 md:space-x-8 items-center text-white/85 font-medium" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "absolute left-0 right-0 container mx-auto mt-5" }
const _hoisted_8 = { class: "flex md:flex-row flex-col md:items-start justify-start space-y-4 md:space-y-0 py-4 px-4 mx-4 md:mx-16 rounded-b-xl bg-neutral-800 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50" }

import { ref } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {


const router = useRouter();

const showProjects = ref(false);
const isOptionsExpanded = ref(false);

function setOption(option: string) {
  isOptionsExpanded.value = false;
  if (option === 'rocket-tickets') {
    router.push('/rocket-tickets');
  } else if (option === 'online-appointment-booking') {
    router.push('/online-appointment-booking');
  } else if (option === 'lillink') {
    window.open('https://lillink.co', '_blank');
  }
}

function handleShowProjects() {
  showProjects.value = !showProjects.value;
}

function getSelectedTab() {
  const path = router.currentRoute.value.path;
  if (path === '/' || path === '/home') {
    return 'home';
  }
  else {
    const pathArray = path.split('/');
    if (pathArray[1].toLowerCase() == 'rocket-tickets' || pathArray[1].toLowerCase() == 'online-appointment-booking') {
      return 'projects';
    } else {
      return pathArray[1].toLowerCase();
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: "w-16 h-16"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          (getSelectedTab() === 'home')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "font-bold border-b-2 border-green-500 text-green-500",
                to: "/"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "hover:text-green-500 font-medium",
                to: "/"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              })),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: _normalizeClass(["flex items-center justify-between space-x-2 hover:text-green-500 font-medium", { 'font-bold text-green-500': getSelectedTab() === 'projects' }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isOptionsExpanded.value = !isOptionsExpanded.value)),
              onBlur: _cache[1] || (_cache[1] = ($event: any) => (isOptionsExpanded.value = false))
            }, [
              _cache[9] || (_cache[9] = _createElementVNode("span", null, "Projects", -1)),
              (_openBlock(), _createElementBlock("svg", {
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor",
                class: _normalizeClass(["h-4 w-4 transform transition-transform duration-200 ease-in-out", isOptionsExpanded.value ? 'rotate-180' : 'rotate-0'])
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M19 9l-7 7-7-7"
                }, null, -1)
              ]), 2))
            ], 34),
            _createVNode(_Transition, {
              "enter-active-class": "transform transition duration-500 ease-custom",
              "enter-class": "-translate-y-1/2 scale-y-0 opacity-0",
              "enter-to-class": "translate-y-0 scale-y-100 opacity-100",
              "leave-active-class": "transform transition duration-300 ease-custom",
              "leave-class": "translate-y-0 scale-y-100 opacity-100",
              "leave-to-class": "-translate-y-1/2 scale-y-0 opacity-0"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: "flex flex-row flex-1 items-center space-x-2 h-10 group",
                      onMousedown: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (setOption('rocket-tickets')), ["prevent"]))
                    }, _cache[10] || (_cache[10] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "",
                        class: "w-9 rounded-lg"
                      }, null, -1),
                      _createElementVNode("div", { class: "flex flex-col items-start" }, [
                        _createElementVNode("div", { class: "group-hover:text-green-500/85" }, "Rocket Tickets"),
                        _createElementVNode("div", { class: "text-sm text-gray-500 group-hover:text-green-500/50" }, "Salon management app")
                      ], -1)
                    ]), 32),
                    _createElementVNode("div", {
                      class: "flex flex-row flex-1 items-center space-x-2 h-10 group",
                      onMousedown: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (setOption('online-appointment-booking')), ["prevent"]))
                    }, _cache[11] || (_cache[11] = [
                      _createElementVNode("div", { class: "w-9 h-9 rounded-lg bg-gradient-to-t from-indigo-700 to-indigo-500 flex items-center justify-center" }, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          alt: "",
                          class: "w-6 h-6 rounded-lg"
                        })
                      ], -1),
                      _createElementVNode("div", { class: "flex flex-col items-start" }, [
                        _createElementVNode("div", { class: "group-hover:text-green-500/85" }, "Appointment Booking"),
                        _createElementVNode("div", { class: "text-sm text-gray-500 group-hover:text-green-500/50" }, "Online appointment booking service")
                      ], -1)
                    ]), 32),
                    _createElementVNode("div", {
                      class: "flex flex-row flex-1 items-center space-x-2 h-10 group",
                      onMousedown: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (setOption('lillink')), ["prevent"]))
                    }, _cache[12] || (_cache[12] = [
                      _createElementVNode("div", { class: "w-9 h-9 rounded-lg bg-gradient-to-t from-blue-700 to-blue-500 flex items-center justify-center" }, [
                        _createElementVNode("img", {
                          src: _imports_3,
                          alt: "",
                          class: "w-6 h-6 rounded-lg"
                        })
                      ], -1),
                      _createElementVNode("div", { class: "flex flex-col items-start" }, [
                        _createElementVNode("div", { class: "group-hover:text-green-500/85" }, "Lil' Link"),
                        _createElementVNode("div", { class: "text-sm text-gray-500 group-hover:text-green-500/50" }, "URL shortener/QR code")
                      ], -1)
                    ]), 32)
                  ])
                ], 512), [
                  [_vShow, isOptionsExpanded.value]
                ])
              ]),
              _: 1
            })
          ]),
          (getSelectedTab() === 'blogs')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                class: "font-bold border-b-2 border-green-500 text-green-500",
                to: "/blogs"
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Blogs")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                class: "hover:text-green-500 font-medium",
                to: "/blogs"
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Blogs")
                ])),
                _: 1
              })),
          (getSelectedTab() === 'about')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 4,
                class: "font-bold border-b-2 border-green-500 text-green-500",
                to: "/about"
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("About")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 5,
                class: "hover:text-green-500 font-medium",
                to: "/about"
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("About")
                ])),
                _: 1
              }))
        ])
      ])
    ])
  ]))
}
}

})