import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/ll-widget.png'


const _hoisted_1 = {
  href: "https://lillink.co/",
  target: "_blank",
  class: "bg-green-900 pt-4 px-4 rounded-xl h-96 hover:bg-green-800"
}

import Vue from 'vue';
import Options from 'vue-class-component';

export default /*@__PURE__*/_defineComponent({
  __name: 'LilLinkWidget',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"flex flex-col md:items-start\"><div class=\"flex flex-col items-start\"><div class=\"text-xl font-bold text-white/85\">Lil&#39; Link</div><div class=\"text-white/85\">#web-app</div></div><div class=\"my-4 text-l font-medium text-white/85\"> lillink.co - URL shortener/QR code generator. </div><img alt=\"\" src=\"" + _imports_0 + "\" class=\"h-[236px] md:h-[260px] w-full rounded-t-xl bg-neutral-900 object-contain\"></div>", 1)
  ])))
}
}

})