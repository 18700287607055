import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-sm" }

import  { useRoute } from 'vue-router';

export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsTabButton',
  props: {
    tabName: {},
    pathName: {}
  },
  setup(__props: any) {

const route = useRoute();

const props = __props;

function getSelectedTab() {
  console.log(route.path);
  if (route.path === '/rocket-tickets' || route.path === '/rocket-tickets/' || route.path === '/rocket-tickets/overview') {
    return 'overview';
  }
  if (route.path === '/rocket-tickets/auth') {
    return 'sign in';
  }
  else {
    const pathArray = route.path.split('/');
    if (pathArray.length > 1) {
      console.log(pathArray[2].toLowerCase());
      return pathArray[2].toLowerCase();
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (getSelectedTab() === 'overview' && props.tabName.toLowerCase() === 'overview')
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "text-amber-600 border-b-2 border-amber-600 font-bold",
          to: "/rocket-tickets"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.tabName), 1)
          ]),
          _: 1
        }))
      : (getSelectedTab() !== 'overview' && props.tabName.toLowerCase() === 'overview')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "hover:text-amber-600 text-black font-medium",
            to: "/rocket-tickets"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.tabName), 1)
            ]),
            _: 1
          }))
        : (getSelectedTab() === props.tabName.toLowerCase())
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              class: "text-amber-600 border-b-2 border-amber-600 font-bold",
              to: `/rocket-tickets/${props.pathName}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.tabName), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 3,
              class: "hover:text-amber-600 text-black font-medium",
              to: `/rocket-tickets/${props.pathName.toLowerCase()}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.tabName), 1)
              ]),
              _: 1
            }, 8, ["to"]))
  ]))
}
}

})