import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home mb-auto" }
const _hoisted_2 = { class: "bg-neutral-800" }
const _hoisted_3 = { class: "container mx-auto" }
const _hoisted_4 = { class: "md:flex-1 mb-4" }
const _hoisted_5 = { class: "text-red-500 text-sm" }
const _hoisted_6 = { class: "md:flex-1 mb-4" }
const _hoisted_7 = { class: "text-red-500 text-sm" }
const _hoisted_8 = { class: "text-red-500 text-sm" }

import Footer from '@/template/Footer.vue';
import NavBar from '@/template/NavBar.vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';
import { onMounted, ref } from 'vue';

import {
  getAuth,
  getRedirectResult,
  signInWithRedirect,
    GoogleAuthProvider,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'

import { useCurrentUser } from 'vuefire'
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'RocketTicketsAuth',
  setup(__props) {


const auth = getAuth();
const authError = ref('');

const email = ref('');
const emailError = ref('');
const password = ref('');
const passwordError = ref('');

const user = useCurrentUser()

function validateEmail(email: string) {
  if (email === '') {
    emailError.value = 'Email is required';
    return false;
  } else {
    emailError.value = '';
    return true;
  }
}

function validatePassword(password: string) {
  if (password === '') {
    passwordError.value = 'Password is required';
    return false;
  } else {
    passwordError.value = '';
    return true;
  }
}

function submitForm() {
  if (!validateEmail(email.value) || !validatePassword(password.value)) {
    return;
  }

  signInWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
        authError.value = error.message.split(':')[1];
      }).then(
      () => {
        // go to another page
        router.push('/rocket-tickets/store');
      }
  );
}

function handleInputChange() {
}

onMounted(() => {
  // signInWithRedirect(auth, new GoogleAuthProvider());
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NavBar)
    ]),
    _createVNode(RocketTicketsNavBar),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(submitForm, ["prevent"]),
          autocomplete: "on",
          class: "flex flex-col bg-white mx-4 md:mx-16 my-16 p-8 rounded-xl shadow-xl md:space-x-4"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              class: "block text-gray-500 text-sm mb-2 text-justify",
              for: "email"
            }, " Email ", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
              onInput: handleInputChange,
              type: "email",
              id: "email",
              name: "email",
              class: "w-full p-2 border border-gray-400 rounded-xl",
              required: ""
            }, null, 544), [
              [_vModelText, email.value]
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(emailError.value), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("label", {
              class: "block text-gray-500 text-sm mb-2 text-justify",
              for: "password"
            }, " Password ", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
              onInput: handleInputChange,
              type: "password",
              id: "password",
              name: "password",
              class: "w-full p-2 border border-gray-400 rounded-xl",
              required: ""
            }, null, 544), [
              [_vModelText, password.value]
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(passwordError.value), 1)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("button", {
            type: "submit",
            class: "bg-blue-500 text-white p-2 rounded-md"
          }, "Sign in", -1)),
          _createElementVNode("div", _hoisted_8, _toDisplayString(authError.value), 1)
        ], 32)
      ])
    ]),
    _createVNode(Footer)
  ]))
}
}

})