<template>
  <div class="home mb-auto bg-neutral-900 text-white/85">
    <NavBar/>

    <div class="container mx-auto mb-16">
      <div class="mx-4 md:mx-16 flex flex-col justify-center items-center">

        <div class="mt-16 text-4xl font-bold text-center text-indigo-500">Online Appointment Bookings</div>
        <div class="mt-4 text-xl font-bold text-center text-neutral-500">Easy to choose available time slot  &#183;  Notify to salon's devices &#183; Remind customer when the appointment coming</div>

        <div class="flex md:flex-row flex-col items-center mt-16 md:space-x-8 space-y-4">
          <img alt="" src="@/assets/booking/booking-1.png" class="h-96 w-64 object-cover object-top rounded-xl" />
          <img alt="" src="@/assets/booking/booking-2.png" class="h-96 w-64 object-cover object-top rounded-xl" />
          <img alt="" src="@/assets/booking/booking-3.png" class="h-96 w-64 object-cover object-top rounded-xl" />
        </div>

        <div class="flex md:flex-row flex-col items-center md:mt-8 mt-4 md:space-x-8 space-y-4">
          <img alt="" src="@/assets/booking/booking-4.png" class="h-96 w-64 object-cover object-top rounded-xl" />
          <img alt="" src="@/assets/booking/booking-5.png" class="h-96 w-64 object-cover object-top rounded-xl" />
          <img alt="" src="@/assets/booking/booking-6.png" class="h-96 w-64 object-cover object-top rounded-xl" />
        </div>

        <div class="my-16 text-4xl font-bold text-indigo-500 text-center">Check out our customers</div>

        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4">
          <div v-for="store in stores" :key="store.cute_id" class="">
            <router-link class="" :to="{name: 'appointment-booking', params: { storeCuteID: store.cute_id }}">
              <div class="text-indigo-500/85 bg-neutral-800 hover:bg-neutral-700 rounded-xl w-64 p-4">
                <div class="text-xl font-bold">{{ store.name }}</div>
                <div class="">{{ store.address }}</div>
                <div class="">{{ store.phone }}</div>
              </div>
            </router-link>
          </div>
        </div>

      </div>
    </div>


    <Footer/>
  </div>
</template>

<script setup lang="ts">

import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';

const stores = [
  {
    name: 'Test Salon',
    cute_id: 'test-salon',
    address: '246 Saratoga Ave, Santa Clara, CA 95051',
    phone: '(408) 554-7100'
  },
  {
    name: 'Morgan Oasis Nails',
    cute_id: 'morgan-oasis-nails',
    address: '1719 S Morgan Rd, Oklahoma City, OK 73128',
    phone: '(405) 265-2494'
  },
  {
    name: 'Perfect Nails Moore',
    cute_id: 'perfect-nails-moore',
    address: '1605 S I-35 Service Rd, Moore, OK 73160',
    phone: '(405) 790-1195'
  },
  {
    name: 'Dragon Nails and Spa',
    cute_id: 'dragon-nails-spa',
    address: '2420 NW 150th St #2, Oklahoma City, OK 73134',
    phone: '(405) 849-5672'
  },
  // {
  //   name: 'Nail It',
  //   cute_id: 'FORO9YnEzsgKSeeyzbY7Gzn0oMn2'
  // },

];
</script>
