<template>
  <div class="sticky bg-neutral-800/50 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50 top-0 h-16">
    <div class="container flex flex-col mx-auto">
      <div class="flex items-center justify-between mx-4 md:mx-16">
        <!-- Logo -->
        <div class="">
          <router-link to="/">
            <img src="../assets/logo.png" alt="" class="w-16 h-16"/>
          </router-link>
        </div>
        <!-- Menu -->
        <div class="flex space-x-4 md:space-x-8 items-center text-white/85 font-medium">
          <router-link v-if="getSelectedTab() === 'home'" class=" font-bold border-b-2 border-green-500 text-green-500" to="/">Home</router-link>
          <router-link v-else class="hover:text-green-500 font-medium" to="/">Home</router-link>

            <div class="">
              <button
                  class="flex items-center justify-between space-x-2 hover:text-green-500 font-medium"
                  :class="{ 'font-bold text-green-500': getSelectedTab() === 'projects' }"
                  @click="isOptionsExpanded = !isOptionsExpanded"
                  @blur="isOptionsExpanded = false"
              >
                <span>Projects</span>
                <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
                    :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <transition
                  enter-active-class="transform transition duration-500 ease-custom"
                  enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                  enter-to-class="translate-y-0 scale-y-100 opacity-100"
                  leave-active-class="transform transition duration-300 ease-custom"
                  leave-class="translate-y-0 scale-y-100 opacity-100"
                  leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
              >
                <div v-show="isOptionsExpanded" class="absolute left-0 right-0 container mx-auto mt-5">
                  <div class="flex md:flex-row flex-col md:items-start justify-start space-y-4 md:space-y-0  py-4 px-4 mx-4 md:mx-16  rounded-b-xl bg-neutral-800 isolate text-white/85 backdrop-blur ring-[0.5px] ring-neutral-500/50">

                    <div class="flex flex-row flex-1 items-center space-x-2 h-10 group" @mousedown.prevent="setOption('rocket-tickets')">
                      <img src="../assets/rocket-ticket-logo.png" alt="" class="w-9 rounded-lg"/>
                      <div class="flex flex-col items-start">
                        <div class="group-hover:text-green-500/85">Rocket Tickets</div>
                        <div class="text-sm text-gray-500 group-hover:text-green-500/50">Salon management app</div>
                      </div>
                    </div>

                    <div class="flex flex-row flex-1 items-center space-x-2 h-10 group" @mousedown.prevent="setOption('online-appointment-booking')">
                      <div class="w-9 h-9 rounded-lg bg-gradient-to-t from-indigo-700 to-indigo-500 flex items-center justify-center">
                        <img src="../assets/calendar.png" alt="" class="w-6 h-6 rounded-lg"/>
                      </div>
                      <div class="flex flex-col items-start">
                        <div class="group-hover:text-green-500/85">Appointment Booking</div>
                        <div class="text-sm text-gray-500 group-hover:text-green-500/50">Online appointment booking service</div>
                      </div>
                    </div>

                    <div class="flex flex-row flex-1 items-center space-x-2 h-10 group" @mousedown.prevent="setOption('lillink')">
                      <div class="w-9 h-9 rounded-lg bg-gradient-to-t from-blue-700 to-blue-500 flex items-center justify-center">
                        <img src="../assets/link.png" alt="" class="w-6 h-6 rounded-lg"/>
                      </div>
                      <div class="flex flex-col items-start">
                        <div class="group-hover:text-green-500/85">Lil' Link</div>
                        <div class="text-sm text-gray-500 group-hover:text-green-500/50">URL shortener/QR code</div>
                      </div>
                    </div>

                  </div>
                </div>
              </transition>
            </div>
<!--          </div>-->

          <router-link v-if="getSelectedTab() === 'blogs'" class=" font-bold border-b-2 border-green-500 text-green-500" to="/blogs">Blogs</router-link>
          <router-link v-else class="hover:text-green-500 font-medium" to="/blogs">Blogs</router-link>

          <router-link v-if="getSelectedTab() === 'about'" class=" font-bold border-b-2 border-green-500 text-green-500" to="/about">About</router-link>
          <router-link v-else class="hover:text-green-500 font-medium" to="/about">About</router-link>
        </div>
      </div>
    </div>
  </div>


</template>

<script setup lang="ts">

import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const showProjects = ref(false);
const isOptionsExpanded = ref(false);

function setOption(option: string) {
  isOptionsExpanded.value = false;
  if (option === 'rocket-tickets') {
    router.push('/rocket-tickets');
  } else if (option === 'online-appointment-booking') {
    router.push('/online-appointment-booking');
  } else if (option === 'lillink') {
    window.open('https://lillink.co', '_blank');
  }
}

function handleShowProjects() {
  showProjects.value = !showProjects.value;
}

function getSelectedTab() {
  const path = router.currentRoute.value.path;
  if (path === '/' || path === '/home') {
    return 'home';
  }
  else {
    const pathArray = path.split('/');
    if (pathArray[1].toLowerCase() == 'rocket-tickets' || pathArray[1].toLowerCase() == 'online-appointment-booking') {
      return 'projects';
    } else {
      return pathArray[1].toLowerCase();
    }
  }
}
</script>

<style lang="css">

.ease-custom {
  transition-timing-function: cubic-bezier(.61,-0.53,.43,1.43);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 16;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
