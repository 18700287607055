<template>
  <div class="">
    <div class="bg-neutral-800">
      <NavBar/>
    </div>
    <RocketTicketsNavBar/>

<!--    <div class="container mx-auto">-->
      <div class="flex flex-col justify-center items-center">

        <div class="flex flex-col md:flex-row items-center justify-center mt-16">
          <!--          <img alt="" src="../../../../src/assets/rocket_tickets.png" class="w-16 h-16 ml-2"/>-->
          <div class="text-4xl md:text-6xl font-bold text-gray-700">Rocket Tickets</div>
        </div>

        <div class="mb-8 text-2xl font-bold text-amber-600 px-4 text-center">
          The only app you need to manage your salon
        </div>

        <router-link
            class="px-6 py-3 h-12 text-amber-900 font-bold bg-amber-500 rounded-full hover:bg-amber-600"
            to="/rocket-tickets/download">
          Download now
        </router-link>

        <div class="w-full">
          <div class=" flex flex-col md:items-center justify-center px-4 py-16">
            <img alt="" src="@/assets/rocket-tickets/rt-tickets.png" class="md:hidden"/>
            <img alt="" src="@/assets/rocket-tickets/rt-tickets-ipad.png" class="hidden md:block w-[1000px]"/>
            <!--            <div class="text-3xl md:text-4xl   font-bold text-amber-600 mb-4 text-left md:w-[1000px] ml-2 md:pl-4">Schedule appointments</div>-->
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mt-8 text-left md:w-[1000px] ml-2 md:pl-4">Easy to see tickets and bonus tickets</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mt-2 text-left md:w-[1000px] ml-2 md:pl-4">Send summary emails every night</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mt-2 text-left md:w-[1000px] ml-2 md:pl-4">Prevent malicious actor to edit without passcode</div>
          </div>
        </div>

        <div class="bg-amber-600 w-full">
          <div class=" flex flex-col md:items-center justify-center px-4 py-16">
            <div class="text-3xl md:text-4xl font-bold   text-white mb-4 text-left md:w-[1000px] ml-2 md:pl-4">Easy to add ticket</div>
            <div class="text-xl md:text-2xl md:font-bold text-white mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Add ticket or bonus ticket depended on price or service</div>
            <div class="text-xl md:text-2xl md:font-bold text-white mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Take supply cost out automatically</div>
            <div class="text-xl md:text-2xl md:font-bold text-white mb-8 text-left md:w-[1000px] ml-2 md:pl-4">Easy to add tip or discount</div>
            <img alt="" src="@/assets/rocket-tickets/rt-add-ticket.png" class="md:hidden"/>
            <img alt="" src="@/assets/rocket-tickets/rt-add-ticket-ipad.png" class="hidden md:block w-[1000px]"/>
          </div>
        </div>

        <div class="bg-neutral-200 w-full">
          <div class=" flex flex-col md:items-center justify-center px-4 py-16">
            <div class="text-3xl md:text-4xl   font-bold text-amber-600 mb-4 text-left md:w-[1000px] ml-2 md:pl-4">Schedule appointments</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Easy to see all appointments</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Able to set time off for technicians or the salon</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-8 text-left md:w-[1000px] ml-2 md:pl-4">Remember customer information to look up later</div>
            <img alt="" src="@/assets/rocket-tickets/rt-appt.png" class="md:hidden"/>
            <img alt="" src="@/assets/rocket-tickets/rt-appointment-ipad.png" class="hidden md:block w-[1000px]"/>
          </div>
        </div>

        <div class=" w-full">
          <div class=" flex flex-col md:items-center justify-center px-4 py-16">
            <div class="text-3xl md:text-4xl   font-bold text-amber-600 mb-4 text-left md:w-[1000px] ml-2 md:pl-4">Book appointment online</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Easy to choose available time slot</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Notify to salon's devices</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-8 text-left md:w-[1000px] ml-2 md:pl-4">Remind customer when the appointment coming</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-8 text-left md:w-[1000px] ml-2 md:pl-4">
              <router-link class="text-lg md:text-xl hover:bg-amber-600/25 border-amber-600 border-2 rounded-full w-fit text-amber-600 py-2 px-4" to="/online-appointment-booking">
                Learn more
              </router-link>
            </div>
            <img alt="" src="@/assets/rocket-tickets/rt-online-appt.png" class="md:hidden"/>
            <img alt="" src="@/assets/rocket-tickets/rt-book-appt-ipad.png" class="hidden md:block w-[1000px]"/>
          </div>
        </div>

        <div class="bg-neutral-200 w-full">
          <div class=" flex flex-col md:items-center justify-center px-4 py-16">
            <div class="text-3xl md:text-4xl   font-bold text-amber-600 mb-4 text-left md:w-[1000px] ml-2 md:pl-4">Payroll</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Calculate payroll for any period of time</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Send payroll emails for technicians</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-8 text-left md:w-[1000px] ml-2 md:pl-4">Generate payroll PDFs</div>
            <img alt="" src="@/assets/rocket-tickets/rt-payroll.png" class="md:hidden"/>
            <img alt="" src="@/assets/rocket-tickets/rt-payroll-ipad.png" class="hidden md:block w-[1000px]"/>
          </div>
        </div>

        <div class=" w-full">
          <div class=" flex flex-col md:items-center justify-center px-4 py-16">
            <div class="text-3xl md:text-4xl   font-bold text-amber-600 mb-4 text-left md:w-[1000px] ml-2 md:pl-4">Sync between devices</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Sync in real-time</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-2 text-left md:w-[1000px] ml-2 md:pl-4">Unlimited devices</div>
            <div class="text-xl md:text-2xl md:font-bold text-amber-600 mb-8 text-left md:w-[1000px] ml-2 md:pl-4">Works on iOS, Android, Kindle, Windows</div>
            <img alt="" src="@/assets/rocket-tickets/rt-sync.png" class="md:hidden"/>
            <img alt="" src="@/assets/rocket-tickets/rt-sync-ipad.png" class="hidden md:block w-[1000px]"/>
          </div>
        </div>




        <router-link
            class="px-6 py-3 mb-16 text-amber-900 font-bold bg-amber-500 rounded-full hover:bg-amber-600"
            to="/rocket-tickets/download">
          Download now
        </router-link>




      </div>
    </div>


<!--  </div>-->


  <!--    Footer place holder    -->
  <Footer/>
  <!--  </div>-->
</template>

<script setup lang="ts">
import NavBar from '@/template/NavBar.vue';
import Footer from '@/template/Footer.vue';
import RocketTicketsWidget from '@/components/home-component/RocketTicketsWidget.vue';
import { onBeforeMount, ref } from 'vue';
import RocketTicketsNavBar from '@/views/projects/rocket-tickets/RocketTicketsNavBar.vue';

const stores = [
  // {
  //   name: 'Morgan Oasis Nails',
  //   id: 'I3Ina91G01SwYYFDaNiApzxBXkg1'
  // },
  // {
  //   name: 'Perfect Nails Moore',
  //   id: 'G8N9aFUCxfVQNTINxwTppyowkko1'
  // },
  // {
  //   name: 'Dragon Nails Spa',
  //   cute_id: 'dragon-nails-spa'
  // },
  // {
  //   name: 'Nail It',
  //   id: 'FORO9YnEzsgKSeeyzbY7Gzn0oMn2'
  // },
  {
    name: 'Test Salon',
    cute_id: 'test-salon'
  },
];

const atTopOfPage = ref(true);

onBeforeMount(() => {
  window.addEventListener('scroll', handleScroll);
});

function handleScroll() {
  // when the user scrolls, check the pageYOffset
  atTopOfPage.value = window.scrollY <= 0;
}
</script>

<style scoped>
.scrolled {
  background-color: #f3f4f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
