import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/appt.png'


import Vue from 'vue';
import Options from 'vue-class-component';

export default /*@__PURE__*/_defineComponent({
  __name: 'OnlineAppointmentWidget',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/rocket-tickets",
    class: "bg-indigo-900 hover:bg-indigo-800 pt-4 px-4 rounded-xl h-96"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "flex flex-col md:items-start" }, [
        _createElementVNode("div", { class: "flex flex-col items-start" }, [
          _createElementVNode("div", { class: "text-xl font-bold text-white/85" }, "Online Appointment"),
          _createElementVNode("div", { class: "text-white/85" }, "#web-app")
        ]),
        _createElementVNode("div", { class: "my-4 text-l font-medium text-white/85" }, " Help customers to book appointments online and send reminders. "),
        _createElementVNode("img", {
          alt: "",
          src: _imports_0,
          class: "h-[236px] w-full object-cover object-top"
        })
      ], -1)
    ])),
    _: 1
  }))
}
}

})