<template>
<div class="h-screen flex items-center justify-center pb-16">
  Loading...
</div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  components: {
  },
  props: {
    // isLoadingStoreData: Boolean,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
