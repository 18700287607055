import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { cn } from '@/lib/utils'
import { ChevronRight } from 'lucide-vue-next'
import {
  DropdownMenuSubTrigger,
  type DropdownMenuSubTriggerProps,
  useForwardProps,
} from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownMenuSubTrigger',
  props: {
    disabled: { type: Boolean },
    textValue: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DropdownMenuSubTrigger), _mergeProps(_unref(forwardedProps), {
    class: _unref(cn)(
      'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent',
      props.class,
    )
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_unref(ChevronRight), { class: "ml-auto h-4 w-4" })
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})