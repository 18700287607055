import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/rocket-ticket-logo.png'
import _imports_1 from '../../assets/rocket-tickets/rt-tickets.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/rocket-tickets",
    class: "bg-amber-900 hover:bg-amber-800 pt-4 px-4 rounded-xl h-96"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "flex flex-col" }, [
        _createElementVNode("div", { class: "flex flex-row items-center" }, [
          _createElementVNode("img", {
            alt: "",
            src: _imports_0,
            class: "w-12 h-12 rounded-lg"
          }),
          _createElementVNode("div", { class: "flex flex-col items-start ml-2" }, [
            _createElementVNode("div", { class: "text-xl font-bold text-white/85" }, "Rocket Tickets"),
            _createElementVNode("div", { class: "text-white/85" }, "#mobile-app")
          ])
        ]),
        _createElementVNode("div", { class: "my-4 text-l font-medium text-white/85 ml-0" }, " An app to manage your salon tickets, appointments, and payrolls. "),
        _createElementVNode("img", {
          alt: "",
          src: _imports_1,
          class: "h-[236px] w-full object-cover object-top"
        })
      ], -1)
    ])),
    _: 1
  }))
}