import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import { VueFire, VueFireAuth } from 'vuefire';
// import { firebaseApp } from './firebase';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

// Use calendar defaults (optional)

// import VCalendar, { Calendar, DatePicker } from 'v-calendar';
// import 'v-calendar/style.css';

// Use plugin with optional defaults
// App.use(VCalendar, {})


import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'
// ... other firebase imports

const firebaseConfig = {
  apiKey: "AIzaSyB8uf6UB8DoJ4bs2GulP6kYsVt89G9FmWI",
  authDomain: "no-receptionist-1fb70.firebaseapp.com",
  projectId: "no-receptionist-1fb70",
  storageBucket: "no-receptionist-1fb70.appspot.com",
  messagingSenderId: "1012433227506",
  appId: "1:1012433227506:web:59fd70629164e0ad5fca6b",
  measurementId: "G-6PRVX06T8G"
};

export const firebaseApp = initializeApp(firebaseConfig)

// used for the firestore refs
const db = getFirestore(firebaseApp)

// here we can export reusable database references
export const todosRef = collection(db, 'todos')



/* add icons to the library */
library.add(faUserSecret);

createApp(App)
  .use(router)
  .use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuth(),
    ],
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  // .component('v-calendar', VCalendar)
  // .component('VCalendar', Calendar)
  // .component('VDatePicker', DatePicker)
  .mount('#app');
