<template>
  <div class="flex flex-row items-center mr-2 space-x-1">
    <img :src="icon" class="h-4 w-4" alt="https://img.icons8.com/color/48/000000/code.png">
    <div v-if="showText" class="text-sm">{{ tech }}</div>
  </div>
</template>

<script>

export default {
  name: 'TechIcon',
  components: {
  },
  data() {
    return {
      icon : this.getIcon(),
    };
  },
  props: {
    tech: String,
    showText: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getIcon() {
      switch (this.tech) {
        case 'Vue':
          return 'https://img.icons8.com/color/48/000000/vue-js.png';
        case 'React':
          return 'https://img.icons8.com/color/48/000000/react-native.png';
        case 'Angular':
          return 'https://img.icons8.com/color/48/000000/angularjs.png';
        case 'Node':
          return 'https://img.icons8.com/color/48/000000/nodejs.png';
        case 'Express':
          return 'https://img.icons8.com/color/48/000000/nodejs.png';
        case 'MongoDB':
          return 'https://img.icons8.com/color/48/000000/mongodb.png';
        case 'PostgreSQL':
          return 'https://img.icons8.com/color/48/000000/postgreesql.png';
        case 'MySQL':
          return 'https://img.icons8.com/color/48/000000/mysql-logo.png';
        case 'Docker':
          return 'https://img.icons8.com/color/48/000000/docker.png';
        case 'Kubernetes':
          return 'https://img.icons8.com/color/48/000000/kubernetes.png';
        case 'AWS':
          return 'https://img.icons8.com/color/48/000000/amazon-web-services.png';
        case 'GCP':
          return 'https://img.icons8.com/color/48/000000/google-cloud-platform.png';
        case 'Azure':
          return 'https://img.icons8.com/color/48/000000/microsoft-azure.png';
        case 'Python':
          return 'https://img.icons8.com/color/48/000000/python.png';
        case 'Java':
          return 'https://img.icons8.com/color/48/000000/java-coffee-cup-logo.png';
        case 'C#':
          return 'https://img.icons8.com/color/48/000000/c-sharp-logo.png';
        case 'C++':
          return 'https://img.icons8.com/color/48/000000/c-plus-plus-logo.png';
        case 'C':
          return 'https://img.icons8.com/color/48/000000/c-programming.png';
        case 'Ruby':
          return 'https://img.icons8.com/color/48/000000/ruby-programming-language.png';
        case 'Go':
          return 'https://icon.icepanel.io/Technology/svg/Go.svg';
        case 'Rust':
          return 'https://img.icons8.com/color/48/000000/rust.png';
        case 'Flutter':
          return 'https://img.icons8.com/color/48/000000/flutter.png';
        case 'Dart':
          return 'https://icon.icepanel.io/Technology/svg/Dart.svg';
        case 'Firebase':
          return 'https://img.icons8.com/color/48/000000/firebase.png';
        case 'Firestore':
          return 'https://img.icons8.com/color/48/000000/google-firebase-console.png';
        case 'JavaScript':
          return 'https://img.icons8.com/color/48/000000/javascript.png';
        case 'TypeScript':
          return 'https://img.icons8.com/color/48/000000/typescript.png';
        case 'HTML':
          return 'https://img.icons8.com/color/48/000000/html-5.png';
        case 'CSS':
          return 'https://img.icons8.com/color/48/000000/css3.png';
        case 'Bootstrap':
          return 'https://img.icons8.com/color/48/000000/bootstrap.png';
        case 'Flask':
          return 'https://img.icons8.com/?size=100&id=MHcMYTljfKOr&format=png&color=FFFFFF';
        case 'Vue.js':
          return 'https://img.icons8.com/color/48/000000/vue-js.png';
        case 'Terraform':
          return 'https://img.icons8.com/color/48/000000/terraform.png';
        case 'Ansible':
          return 'https://icon.icepanel.io/Technology/png-shadow-512/Ansible.png';
        case 'Azure DevOps':
          return 'https://img.icons8.com/?size=100&id=VLKafOkk3sBX&format=png&color=000000';
        case 'Bash':
          return 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Bash_Logo_black_and_white_icon_only.svg/1024px-Bash_Logo_black_and_white_icon_only.svg.png?20180723054438';
        case 'Gradle':
          return 'https://gradle.com/wp-content/themes/fuel/assets/img/branding/gradle-elephant-icon-gradient.svg';
        case 'Tailwind CSS':
          return 'https://icon.icepanel.io/Technology/svg/Tailwind-CSS.svg';
        case 'Selenium':
          return 'https://icon.icepanel.io/Technology/svg/Selenium.svg';
        case 'Anaconda':
          return 'https://icon.icepanel.io/Technology/svg/Anaconda.svg';
        case 'Jupyter':
          return 'https://icon.icepanel.io/Technology/svg/Jupyter.svg';
        case 'AWS':
          return 'https://img.icons8.com/color/48/000000/amazon-web-services.png';
        case 'Arduino':
          return 'https://icon.icepanel.io/Technology/svg/Arduino.svg';
        case 'Consul':
          return 'https://icon.icepanel.io/Technology/svg/Consul.svg';
        case 'Figma':
          return 'https://icon.icepanel.io/Technology/svg/Figma.svg';
        case 'Git':
          return 'https://img.icons8.com/color/48/000000/git.png';
        case 'Grafana':
          return 'https://icon.icepanel.io/Technology/svg/Grafana.svg';
        case 'Prometheus':
          return 'https://icon.icepanel.io/Technology/svg/Prometheus.svg';
        // case 'DevOps':
        //   return 'https://p1.hiclipart.com/preview/308/533/209/logo-blue-line-devops-azure-electric-blue-symbol-png-clipart.jpg';
        default:
          return 'https://img.icons8.com/color/48/000000/code.png';
}},}};

</script>
