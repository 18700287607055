import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/banner.png'
import _imports_1 from '../assets/banner2.jpg'


const _hoisted_1 = { class: "home bg-neutral-900" }
const _hoisted_2 = { class: "text-white/85" }
const _hoisted_3 = { class: "container mx-auto" }
const _hoisted_4 = { class: "bg-neutral-800 flex flex-col md:flex-row justify-center items-center md:mx-16 py-16 md:py-20 md:rounded-b-xl md:px-8" }
const _hoisted_5 = { class: "flex flex-col md:w-1/2 mt-16 md:mt-2 justify-center" }
const _hoisted_6 = { class: "flex justify-center md:justify-start mt-4 md:mt-4" }
const _hoisted_7 = { class: "container mx-auto" }
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-3 gap-8 mx-4 md:mx-16 mb-8" }
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-1 gap-8 mx-4 md:mx-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_RocketTicketsWidget = _resolveComponent("RocketTicketsWidget")!
  const _component_OnlineAppointmentWidget = _resolveComponent("OnlineAppointmentWidget")!
  const _component_LilLinkWidget = _resolveComponent("LilLinkWidget")!
  const _component_WebsiteDesignWidget = _resolveComponent("WebsiteDesignWidget")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar),
    _cache[3] || (_cache[3] = _createElementVNode("section", {
      id: "hero",
      class: "bg-neutral-900"
    }, [
      _createElementVNode("div", { class: "container flex flex-col mx-auto space-y-0 md:space-y-0 justify-evenly" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          class: "md:hidden"
        }),
        _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          class: "hidden md:block md:mx-16"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-4xl md:w-1/2 font-bold font-jetbrains font-mono md:text-6xl text-center md:text-left" }, [
            _createTextVNode(" Software Lab "),
            _createElementVNode("div", { class: "text-lg text-green-500/85 ml-2 mt-2 font-mono" }, "Fueled by iced coffee")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-center justify-center md:text-left mx-4 md:mx-0" }, [
              _createTextVNode(" Howdy! I'm Isaac, and welcome to my world of side projects – "),
              _createElementVNode("span", { class: "text-green-500/85" }, "3AM Lab"),
              _createTextVNode(". Born during my college \"vampire mode\" nights, these creations are fueled by late-night inspiration. Although my day job is now a thing, the 3AM name lives on because that's when software gets made. "),
              _createElementVNode("br"),
              _createTextVNode("Happy coding and stay caffeinated 🔥 ")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "px-4 py-2 h-10 text-black bg-green-500/75 rounded-full hover:bg-green-500 baseline",
                to: "/about"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Contact me")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"container mx-auto md:my-8\"><div class=\"bg-neutral-900 md:mx-16 md:rounded-xl flex flex-col md:flex-row py-16 md:space-x-8 md:items-start md:justify-items-start items-center justify-center text-sm md:text-md font-jetbrains font-mono\"><div class=\"text-gray-400\"># What I&#39;m working on</div><div class=\"text-white/85\"><span class=\"text-green-500/85\">trinhvu@3amlab</span>:<span class=\"text-blue-500/85\">~/projects</span>$ ls -l</div></div></div>", 1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_RocketTicketsWidget),
        _createVNode(_component_OnlineAppointmentWidget),
        _createVNode(_component_LilLinkWidget)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_WebsiteDesignWidget)
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "my-8" }, ".", -1)),
    _createVNode(_component_Footer)
  ]))
}