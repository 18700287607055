<template>
<!--  <div class="text-md md:text-lg mb-6 text-indigo-500 text-left">May we have your name and phone number?<br/>We will text you to confirm and remind of the appointment**</div>-->

  <div class=""></div>
  <form @submit.prevent="submitForm" class="bg-white md:flex md:flex-row md:space-x-4">
    <div class="md:flex-1 mb-4">
      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="firstName">
        First Name *
      </label>
      <input
        v-model="customer.firstName"
        @input="handleInputChange"
        type="text"
        id="firstName"
        name="firstName"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
      />
    </div>

    <div class="md:flex-1 mb-4">
      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="lastName">
        Last Name *
      </label>
      <input
        v-model="customer.lastName"
        @input="handleInputChange"
        type="text"
        id="lastName"
        name="lastName"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
      />
    </div>

    <div class="md:flex-1 mb-4">
      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="phone">
        Phone *
      </label>
      <input
        v-model="customer.phone"
        @input="handleInputChange"
        type="tel"
        id="phone"
        name="phone"
        class="w-full p-2 border border-gray-400 rounded-xl"
        required
      />
    </div>

    <!--    <div class="mb-4">-->
    <!--      <label class="block text-gray-500 text-sm font-bold mb-2 text-justify" for="email" >-->
    <!--        Email (if you'd like to receive email instead of text)-->
    <!--      </label>-->
    <!--      <input v-model="customer.email" type="email" id="email" name="email"-->
    <!--             class="w-full p-2 border border-gray-400 rounded-xl" required>-->
    <!--    </div>-->

    <!--    <button type="submit" class="bg-blue-500 text-white p-2 rounded-md">Submit</button>-->

  </form>

  <div class="flex flex-col items-start">
    <div class="mt-6 text-gray-500 text-sm">* Required</div>
<!--    <div class="mb-6 text-gray-500 ">** We only text you if we have your permission</div>-->
  </div>



</template>

<script>
export default {
  name: "CustomerInfoTab",
  components: {},
  props: {
    editingCustomer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customer: { ...this.editingCustomer },
    };
  },
  methods: {
    // submitForm() {
    //   console.log(this.customer);
    //   this.$emit('updateCustomer', this.customer);
    // },
    formatPhoneNumber(input) {
      //returns (###) ###-####
      input = input.replace(/\D/g, "");
      var size = input.length;
      if (size > 0) {
        input = "(" + input;
      }
      if (size > 3) {
        input = input.slice(0, 4) + ") " + input.slice(4, 11);
      }
      if (size > 6) {
        input = input.slice(0, 9) + "-" + input.slice(9);
      }
      return input;
    },

    handleInputChange() {
      // Run this function every time a text field changes
      console.log("Input changed!", this.customer);

      this.customer.phone = this.formatPhoneNumber(this.customer.phone);

      this.$emit("updateCustomer", this.customer);
    },
  },
};
</script>
