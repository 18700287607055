<template>
<!--  <div class="text-md md:text-lg mb-6 text-indigo-500 text-left">Is any available technician can help you at that time?<br/>Or is there any specific technician you like?</div>-->

  <div class="flex flex-col md:items-center">
    <button class="h-12 w-56 bg-indigo-500 text-white font rounded-xl px-4 mb-3 mx-2 text-sm hover:border hover:border-indigo-800" v-if="isAnyAvailableTech" type="button" @click="switchAnyAvailableTech">
      Any available technician
    </button>
    <button class="h-12 w-56 bg-gray-100 text-black font rounded-xl px-4 mb-3 mx-2 text-sm hover:border hover:border-indigo-500" v-else type="button" @click="switchAnyAvailableTech">
      Any available technician
    </button>
  </div>
  <div class="flex flex-row flex-wrap items-center md:justify-center">
    <StaffButton
      v-for="staff in staffs"
      :key="staff.id"
      :staff="staff"
      :selectingStaffs="selectingStaffs"
      :selectingServices="selectingServices"
      @addStaff="addStaff"
      @removeStaff="removeStaff"
    />
  </div>
</template>

<script>
import StaffButton
  from '@/views/projects/rocket-tickets/appointment/book-appointment/staffs-tab/StaffButton.vue';

export default {
  name: 'StaffsTab',
  components: {
    StaffButton,
  },
  props: {
    staffs: {
      type: Object,
      required: true,
    },
    selectingServices: {
      type: Object,
      required: true,
    },
    selectingStaffs: Array,
    isAnyAvailableTech: Boolean,
  },
  methods: {
    addStaff(staff) {
      console.log(`StaffsTab: Add ${staff.first_name} staff`);
      // console.log(`StaffsWorkingHour: ${staff['staff_working_hours']['monday'][0]['start']}`);
      this.$emit('addStaff', staff);
    },
    removeStaff(staff) {
      console.log(`StaffsTab: Remove ${staff.first_name} staff`);
      this.$emit('removeStaff', staff);
    },
    switchAnyAvailableTech() {
      console.log('StaffsTab: Select any staff');
      this.$emit('switchAnyAvailableTech', this.isAnyAvailableTech);
    },
  },
};
</script>
