<template>
  <!--  <div class="flex flex-col md:flex-row bg-white mt-8">-->
<!--  <div class="flex flex-row sm:flex-row bg-white mt-0 mb-4">-->
    <img
      alt="" src="../../assets/banner.png">
    <!--  Left spacer  -->
<!--    <div class="w-0 lg:basis-1/5"></div>-->

    <!--    <h2 class="font-jetbrains text-5xl sm:text-6xl text-stone-800 text-left">-->
    <!--      THE LAB THAT'S UP UNTIL 3AM-->
    <!--    </h2>-->
    <!--        <img alt="" src="../assets/1.jpg" width="850">-->

<!--    <div class="flex-auto mt-4 sm:basis-1/2">-->
<!--      <div class="ml-4 lg:ml-0 mr-4 lg:mr-0 lg:basis-3/5 ">-->

      <!--      <h1 class="font-jetbrains text-5xl sm:text-7xl text-stone-800 text-left">-->
      <!--        THE LAB THAT'S UP UNTIL 3AM-->
      <!--      </h1>-->
<!--      <h1 class="text-lg text-stone-800 text-left text-sm text-gray-600">-->
<!--        Welcome to my indie software lab! This lab is dedicated to creating-->
<!--        high-quality software that solves real-world problems. It values-->
<!--        creativity, innovation, and the freedom to explore new ideas.-->
<!--      </h1>-->
<!--      <div class="flex flex-row mt-4">-->
<!--        <img alt="" src="../assets/home-banner-arrow.png" class="w-16">-->
<!--        <h1 class="font-mono text-lg sm:text-xl text-stone-800 text-left ml-4">-->
<!--          Explore 3AM Lab's projects-->
<!--        </h1>-->
<!--      </div>-->
<!--    </div>-->

    <!--  Right spacer  -->
<!--    <div class="w-0 lg:basis-1/5"></div>-->
<!--  </div>-->
</template>

<script>
export default {
  name: 'HomeBanner',
};
</script>

<style scoped>

</style>
