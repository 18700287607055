<template>
<!--  <div class="bg-blue-500 px-2 text-white text-sm font-normal rounded-lg" v-if="getDaysDiff() === 0">-->
  <div class="bg-blue-200 px-2 text-blue-500 text-sm font-normal rounded-lg" v-if="getDaysDiff() === 0">
    Today
  </div>
  <div class="bg-red-400 px-2 text-white text-sm font-normal rounded-lg" v-else-if="getDaysDiff() < 0">
    {{ getDaysDiff() }} day{{ getDaysDiff() > 1 ? 's' : '' }}
  </div>
<!--  <div class="bg-emerald-500 px-2 text-white text-sm font-normal rounded-lg" v-else>-->
  <div class="bg-green-200 px-2 text-green-600 text-sm font-normal rounded-lg" v-else>
    + {{ getDaysDiff() }} day{{ getDaysDiff() > 1 ? 's' : '' }}
  </div>
</template>

<script>

export default {
  name: 'DateInfo',
  components: {},
  data() {
    return {
      currentDate: new Date(),
      deltaDays: 0,
    };
  },
  props: {
    selectingDate: String,
  },
  methods: {
    getDaysDiff() {
      let selectingDate = new Date(this.selectingDate.replace(/-/g, '/'));
      const deltaDays = Math.ceil((selectingDate.getTime() - this.currentDate.getTime()) / (1000 * 3600 * 24));
      this.deltaDays = deltaDays;

      return deltaDays;
    }
  }
};

</script>
