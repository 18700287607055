<script setup lang="ts">
import  { useRoute } from 'vue-router';
const route = useRoute();

const props = defineProps<{
  tabName: string;
  pathName: string;
}>();

function getSelectedTab() {
  console.log(route.path);
  if (route.path === '/rocket-tickets' || route.path === '/rocket-tickets/' || route.path === '/rocket-tickets/overview') {
    return 'overview';
  }
  if (route.path === '/rocket-tickets/auth') {
    return 'sign in';
  }
  else {
    const pathArray = route.path.split('/');
    if (pathArray.length > 1) {
      console.log(pathArray[2].toLowerCase());
      return pathArray[2].toLowerCase();
    }
  }
}
</script>

<template>
<!--  <router-link v-if="getSelectedTab() === props.tab.toLowerCase()" class="text-amber-600 border-b-2 border-amber-500 font-bold" :to="`/rocket-tickets/${props.tab}`">{{ props.tab }}</router-link>-->
<!--  <router-link v-else class="hover:text-amber-600 text-black font-medium" :to="`/rocket-tickets/${props.tab.toLowerCase()}`">{{ props.tab }}</router-link>-->
  <div class="text-sm">
    <router-link v-if="getSelectedTab() === 'overview' && props.tabName.toLowerCase() === 'overview'" class="text-amber-600 border-b-2 border-amber-600 font-bold" to="/rocket-tickets">{{ props.tabName }}</router-link>
    <router-link v-else-if="getSelectedTab() !== 'overview' && props.tabName.toLowerCase() === 'overview'"  class="hover:text-amber-600 text-black font-medium" to="/rocket-tickets">{{ props.tabName }}</router-link>
<!--    <router-link v-else-if="getSelectedTab() === 'user' && props.tab.toLowerCase() === 'user'" class="text-amber-600 border-b-2 border-amber-500 font-bold" to="/rocket-tickets/user">USER</router-link>-->
<!--    <router-link v-else-if="getSelectedTab() !== 'user' && props.tab.toLowerCase() === 'user'" class="hover:text-amber-600 text-black font-medium border border-black rounded p-0.5" to="/rocket-tickets/user"><i class="fa-regular fa-user"></i></router-link>-->
    <router-link v-else-if="getSelectedTab() === props.tabName.toLowerCase()" class="text-amber-600 border-b-2 border-amber-600 font-bold" :to="`/rocket-tickets/${props.pathName}`">{{ props.tabName }}</router-link>
    <router-link v-else class="hover:text-amber-600 text-black font-medium" :to="`/rocket-tickets/${props.pathName.toLowerCase()}`">{{ props.tabName }}</router-link>
  </div>

</template>

<style scoped>

</style>
